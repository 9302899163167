import { createSlice } from '@reduxjs/toolkit'

export const userDataSlice = createSlice({
  name: 'userState',
  initialState: {
    userData: {},
    userAvatar: ''
  },
  reducers: {
    handleUserData: (state, action) => {
      state.userData = action.payload
    },
    handleUserAvatar: (state, action) => {
      state.userAvatar = action.payload
    }
  }
})

export const { handleUserData } = userDataSlice.actions
export const { handleUserAvatar } = userDataSlice.actions

export default userDataSlice.reducer
