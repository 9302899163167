import mock from '../mock'
import { v4 as uuid } from 'uuid'

let templateData = [
  {
    templateName: 'demo1',
    srNo: 1,
    id: uuid(),
    auctionData: {
      remarks: [
        {
          id: 1,
          details: 'Need to add extra details',
          rules: 'Selected Duration is too long',
          bom: 'ok',
          information: 'Need new GST Number',
          bidders: 'okay',
          termsAndConditions: 'Perfect',
          createdAt: '2022-03-01T07:30:00.000Z'
        },
        {
          id: 2,
          details: 'details',
          rules: ' Duration is too long',
          bom: 'BOM',
          information: 'Need new GST Number',
          bidders: 'BIDDER',
          termsAndConditions: 'termsAndConditions',
          createdAt: '2022-03-03T07:30:00.000Z'
        }
      ],
      bidders: [
        {
          id: 1,
          supplierCode: '1923205',
          companyName: 'Microtech',
          Officeaddress: 'Wonder IT park, Ghatkopar, Mumbai',
          Officetype: 'Branch Office',
          category: 'PLATINUM',
          businessTillDate: '1107',
          status: 'PENDING_FOR_APPROVAL',
          gst: '18AABCU9603R1ZM',
          email: 'mail@microtect.com',
          contactName: 'MR. CEO',
          contactNumber: 8974563210
        }
      ],
      billOfMaterials: {
        bidMechanism: 'CUMULATIVE LEVEL',
        submittedWithTaxes: true,
        items: [
          {
            id: '4d0ce609-5920-4b17-a496-7aed183a91e2',
            description: 'Cement',
            unit: 'tons',
            quantity: 100
          },
          {
            id: '4d0ce609-5920-4b17-a496',
            description: 'LED Bulbs',
            unit: 'unit',
            quantity: 100
          }
        ]
      },
      details: {
        auctionTitle: '',
        auctionDescription: 'description about this auction.',
        buyersName: 'John Doe',
        location: 'PUNE',
        budget: null,
        currency: 'INR',
        productType: 'GOODS',
        productSegment: 'AUTOMOBILE',
        approver: 'Vaibhav Landge',
        dislpayLeadingBidPrice: true
      },
      information: {
        deliveryAddress: 'pune',
        gstNumber: '18AABCU9603R1ZM',
        pinCode: '411001',
        uploadedFiles: []
      },
      rules: {
        auctionType: 'Reverse Auction',
        bidImprovement: 4,
        startDateTime: '',
        prebidDueDate: '',
        durationToEnd: 100,
        runOverTimeInMinutes: 2,
        activateRunOverBeforeEndInMinutes: 3,
        numberOfRunOverEvents: 4,
        activateRunOver: true,
        activatePrebidPrice: true
      },
      termsAndConditions: {
        acceptTermsAndConditions: false,
        tnc: [
          {
            id: '6604cb80-a513-4a36-9791-86f2b3416389',
            description: 'You should be in the business since 5 years',
            heading: 'In Business Since',
            srNo: 1
          },
          {
            id: '6604cb80-a513-4a36-9791',
            description: 'You should be in the business since 5 years',
            heading: 'In Business Since',
            srNo: 2
          }
        ]
      }
    }
  }
]

mock.onGet('/services/template').reply(() => [200, { data: templateData }])

mock.onPost('/services/template/gettemplate').reply((request) => {
  const id = request.data
  const template = templateData.find((x) => x.id === id)
  return [200, template]
})

mock.onPost('/services/template').reply((request) => {
  const data = JSON.parse(request.data)
  const auctionData = data?.auctionData
  const formatedAuctionData = {
    ...auctionData,
    details: { ...auctionData.details, auctionTitle: '', budget: null },
    rules: { ...auctionData.rules, startDateTime: '', prebidDueDate: '' }
  }
  if (data?.name === undefined) {
    const filteredData = templateData.filter((x) => data.templateOption !== x.templateName)
    const newTemplate = {
      templateName: data?.templateOption,
      srNo: filteredData.length + 1,
      id: uuid(),
      auctionData: formatedAuctionData
    }
    filteredData.push(newTemplate)
    templateData = [...filteredData]
  } else {
    const newTemplate = {
      templateName: data?.name,
      srNo: templateData.length + 1,
      id: uuid(),
      auctionData: formatedAuctionData
    }
    templateData.push(newTemplate)
  }
  const auction = Object.assign({}, templateData)
  return [200, auction]
})

let termsTemplate = [
  {
    templateName: 'demoTemplate1',
    id: uuid(),
    srNo: 1,
    templateData: [
      {
        id: uuid(),
        srNo: 1,
        heading: 'Heading',
        description: 'Description'
      }
    ]
  },
  {
    templateName: 'demoTemplate2',
    id: uuid(),
    srNo: 2,
    templateData: [
      {
        id: uuid(),
        srNo: 1,
        heading: 's',
        description: 'd'
      },
      {
        id: uuid(),
        srNo: 2,
        heading: 's2',
        description: 'd2'
      }
    ]
  }
]

mock.onGet('/services/termstemplate').reply(() => [200, { data: termsTemplate }])

mock.onPost('/services/termstemplate/gettemplate').reply((request) => {
  const id = request.data
  const template = termsTemplate.find((x) => x.id === id)
  return [200, template]
})

mock.onPost('/services/termstemplate').reply((request) => {
  const data = JSON.parse(request.data)
  if (data?.templateOption === 'create template') {
    const newTemplate = {
      templateName: data?.name,
      srNo: termsTemplate.length + 1,
      id: uuid(),
      templateData: data?.termsAndConditions
    }
    termsTemplate.push(newTemplate)
  } else {
    const filteredData = termsTemplate.filter((x) => data.templateOption !== x.templateName)
    const newTemplate = {
      templateName: data?.templateOption,
      srNo: filteredData.length + 1,
      id: uuid(),
      templateData: data?.termsAndConditions
    }
    filteredData.push(newTemplate)
    termsTemplate = [...filteredData]
  }
  const auction = Object.assign({}, termsTemplate)
  return [200, auction]
})
