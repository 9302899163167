// - How to create an auction template?
// - How to edit an auction template?
// - How to delete an auction template?
// - How to create auction from template?

// Range 1300-1399
export default [
    {
      id: 1300,
      question: 'What is Template?',
      slug: 'what-is-template',
      // answer: ansCreateStrongPassword
    },
    {
      id: 1301,
      question: 'How to create an auction template?',
      slug: 'create-auction-template',
      // answer: ansChangePassword
    },
    {
      id: 1302,
      question: 'How to edit or delete an auction template?',
      slug: 'edit-delete-auction-template',
      // answer: ansChangePassword
    },
    {
      id: 1303,
      question: 'How to create auction from template?',
      slug: 'create-auction-from-template',
      // answer: ansChangePassword
    },
  ]