export default `
<p> Only admin can change the a user's Firstname or Lastname. Please follow below steps to edit a user.  </p>
<p> 
<ol>
  <li>Login to MProcure app with admin role </li>
  <li>On a left navbar, click on Setting -> My Organization -> Users and Roles </li>
  <li>Click on "Edit" button. </li>
  <li>Make the desired changes and click "Save". </li>
</ol>
<img src="${require('@src/views/publicServices/documentation/documentationRecords/images/edit-user.png').default}" height="170"/>
</p>
`