export default `
<p> Please follow below steps to change your mobile number.  </p>
<p> 
<ol>
  <li>Login to MProcure app.</li>
  <li>On a left navbar, click on Setting -> My Profile -> Account </li>
  <li>Change the mobile number and click "Save". </li>
</ol>
<img src="${require('@src/views/publicServices/documentation/documentationRecords/images/change-mobile.png').default}" height="300"/>
</p>
`