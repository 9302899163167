export default `
<p> Please follow below steps to login the MProcure platform. </p>
<p> 
<ol>
  <li>Open MProcure website (www.mprocure.in) and click on "Login". This will redirect to MProcure app and open the login page. </li>
  <li>On login page, enter your email/password and click "Sign In" button</li>
</ol>
<p> Congratulations! You have log into the MProcure platform. </p>
<img src="${require('@src/views/publicServices/documentation/documentationRecords/images/login.png').default}" height="400"/>
</p>

`