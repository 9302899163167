import mock from '@src/@fake-db/mock'
import categories from './categories'

const data = categories

mock.onGet('/documentation').reply(() => [200, data.documentationCategories])
mock.onGet('/documentation/category').reply(({ category }) => [200, data[category]])
mock.onGet('/documentation/category/related-question').reply(() => [200, data])
mock.onGet('/documentation/category/question').reply(({ category, question }) => {
  const subCategory = data[category]
  let listOfQuestions = []
  subCategory.forEach((item) => {
    listOfQuestions = [...listOfQuestions, ...item.questions]
    return listOfQuestions
  })

  const result = listOfQuestions.find((Q) => Q.slug === question)
  return [200, result]
})
mock.onGet('/documentation/category/question/current-category').reply(({ category, question }) => {
  const categoryId = data.documentationCategories.find((c) => c.category === category)
  const subCategory = data[category].filter((item) => item.questions.find((q) => q.slug === question))
  const result = { categoryId: categoryId?.id, subCategoryId: subCategory[0]?.id }
  return [200, result]
})
