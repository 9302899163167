export const TODAY = new Date()
export const BID_VALIDITY = 15
export const BID_OPENING_DATE = TODAY

/**
 * TC Properties, which srNo is less than the MANDATORY_TC_LAST_INDEX are mandatory
 *
 * All TC with empty heading will be assigned TC ID = 1000
 *
 * Remaining TCs are non mandatory
 */

export const MANDATORY_TC_LAST_INDEX = 0
export const ADDITIONAL_TC_INDEX = 1000

export const tncCategories = {
  0: 'Payment',
  1: 'Delivery',
  2: 'Loading',
  3: 'Unloading',
  4: 'Freight/Transport',
  101: 'Intellectual Property',
  102: 'Warranty',
  103: 'Licenses',
  104: 'Confidentiality',
  1000: 'Additional'
}

export const tncCategoryAndHeadingsMapping = {
  0: [
    { heading: 'Advance payment', Description: 'Advance payment possible for good discounted rates' },
    {
      heading: 'On Delivery',
      Description: 'Full payment will be done upon successful delivery and acceptance of the goods or services'
    },
    {
      heading: 'On Credit',
      Description: 'Our standard payment terms are within 30 days from the date of the invoice'
    }
  ],
  1: [
    { heading: 'Immediate', Description: 'Delivery required immediately after purchase order issuance' },
    { heading: 'Within Days', Description: 'Delivery required within 7 calendar days of purchase order issuance' }
  ],
  2: [
    { heading: 'Buyer', Description: 'Buyer will bear the charges' },
    { heading: 'Seller', Description: 'Seller will bear the charges' }
  ],
  3: [
    { heading: 'Buyer', Description: 'Buyer will bear the charges' },
    { heading: 'Seller', Description: 'Seller will bear the charges' }
  ],
  4: [
    { heading: 'Including Freight', Description: 'Price inclusive of freight/transport charges' },
    { heading: 'Excluding Freight', Description: 'Price excludes freight/transport charges' }
  ],
  101: [
    {
      heading: 'Agree',
      Description:
        'All intellectual property rights associated with the RFQ and our business processes remain our sole property'
    }
  ],
  102: [
    {
      heading: 'Agree',
      Description:
        'You warrant that the goods and services offered in your Quote meet the specifications and requirements outlined in the RFQ'
    }
  ],
  103: [
    {
      heading: 'Agree',
      Description:
        'You warrant that you have the necessary licenses and qualifications to provide the proposed goods and services'
    }
  ],
  104: [
    {
      heading: 'Agree',
      Description:
        'Any information provided during discussions, and your Quote are considered confidential. You agree not to disclose such information to any third party without our prior written consent'
    }
  ],
  1000: [
    {
      heading: 'Additional',
      Description: 'Add Additional Terms and Conditions here'
    }
  ]
}
