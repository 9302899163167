// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('/customeradmin/termsandconditions/add', async () => {
    try {
        const response = await axios.get('/customeradmin/termsandconditions/add')
        return response.data
    } catch (error) {
     console.log(error)   
    }
})

export const postData = createAsyncThunk('/customeradmin/termsandconditions/add', async (data) => {
    try {
        const response = await axios.post('/customeradmin/termsandconditions/add', data)
        return response.data
    } catch (error) {
     console.log(error)   
    }
})
export const updateData = createAsyncThunk('/customeradmin/termsandconditions/update', async (rowData) => {
  try {
      const response = await axios.put('/customeradmin/termsandconditions/update', rowData)
      return response.data
  } catch (error) {
   console.log(error)   
  }
})
export const deleteData = createAsyncThunk('/customeradmin/termsandconditions/delete', async (id) => {
    try {
        const response = await axios.delete('/customeradmin/termsandconditions/delete', 
         { data: {
            id
          }}
        )
        return response.data
    } catch (error) {
     console.log(error)   
    }
  })

export const termsandconditionsSlice = createSlice({
  name: 'Terms and Conditions',
  initialState: {
    termsandconditions: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.termsandconditions = action.payload
      })
  }
})

export default termsandconditionsSlice.reducer
