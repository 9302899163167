import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  isGstIncludedInPrice: false,
  quotationUrl: [],
  sellerComment: '',
  bomResponse: [],
  tncResponse: [],
  uploadedDocuments: [],
  costMetrics: {
    transport: {
      price: 0,
      gstPercentage: 0
    },
    handling: {
      price: 0,
      gstPercentage: 0
    },
    otherCharges: {
      price: 0,
      gstPercentage: 0
    }
  },
  isCollapseQuotations: '0'
}

export const rfqSlice = createSlice({
  name: 'rfq',
  initialState,
  reducers: {
    setBomResponse: (state, action) => {
      const { itemId, bomId } = action.payload
      const existingIndex = state.bomResponse.findIndex((item) => item.bomId === bomId)
      state.bomResponse = state.bomResponse.filter((item) => {
        // empty unit rate handling for bid materials
        if (item && !!item?.bomId && item?.unitRate) {
          return true
        }
        return false
      })
      if (existingIndex === -1) {
        // Add a new item if it doesn't exist
        state.bomResponse = [...state.bomResponse, action.payload]
      } else {
        // Update the existing item
        state.bomResponse = state.bomResponse.map((item, index) =>
          index === existingIndex ? { ...item, ...action.payload } : item
        )
      }
    },
    setTncResponse: (state, action) => {
      if (
        current(state.tncResponse).length === 0 ||
        !current(state.tncResponse).find((existingResponse) => existingResponse.tncId === action.payload.tncId)
      )
        state.tncResponse = [...state.tncResponse, action.payload]
      else {
        const updateTncResponse = current(state.tncResponse).map((item) => {
          const copyOfTnc = { ...item }
          if (item.tncId === action.payload.tncId) {
            copyOfTnc.tncId = action.payload.tncId
            copyOfTnc.agree = action.payload.agree
          }
          return copyOfTnc
        })
        state.tncResponse = updateTncResponse
      }
    },
    setRequiredDocuments: (state, action) => {
      if (
        current(state.uploadedDocuments)?.find(
          (file) => file.docName.toUpperCase() === action.payload.docName.toUpperCase()
        )
      ) {
        const filterDocuments = current(state.uploadedDocuments).filter(
          (doc) => doc.docName.toUpperCase() !== action.payload.docName
        )
        state.uploadedDocuments = [...filterDocuments, { ...action.payload }]
      } else state.uploadedDocuments = [...state.uploadedDocuments, { ...action.payload }]
    },
    setQuotationUrl: (state, action) => {
      state.quotationUrl = [...state.quotationUrl, action.payload]
    },
    updateQuotationUrl: (state, action) => {
      const updatedFiles = state.quotationUrl.filter((file) => file !== action.payload)
      state.quotationUrl = updatedFiles
    },
    setSellerComment: (state, action) => {
      state.sellerComment = action.payload
    },
    setTransportCharges: (state, action) => {
      state.costMetrics.transport.price = action.payload.price
      state.costMetrics.transport.gstPercentage = action.payload.gstPercentage
    },
    setHandlingCharges: (state, action) => {
      state.costMetrics.handling.price = action.payload.price
      state.costMetrics.handling.gstPercentage = action.payload.gstPercentage
    },
    setAdditionalCharges: (state, action) => {
      state.costMetrics.otherCharges.price = action.payload.price
      state.costMetrics.otherCharges.gstPercentage = action.payload.gstPercentage
    },
    setIsGstIncludedInPrice: (state, action) => {
      state.isGstIncludedInPrice = action.payload
    },
    setIsCollapseQuotations: (state, action) => {
      state.isCollapseQuotations = action.payload
    },
    resetBidState: (state) => {
      return initialState
    }
  }
})

export const {
  setBomResponse,
  setTncResponse,
  resetBidState,
  setRequiredDocuments,
  setQuotationUrl,
  updateQuotationUrl,
  setSellerComment,
  setTransportCharges,
  setHandlingCharges,
  setAdditionalCharges,
  setIsGstIncludedInPrice,
  setIsCollapseQuotations
} = rfqSlice.actions
export default rfqSlice.reducer
