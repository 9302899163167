// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getTableData = createAsyncThunk('/customer-user/supplier-management', async () => {
  const response = await axios.get('/customer-user/supplier-management')
  return response.data
})

export const postUsersData = createAsyncThunk('/customer-user/supplier-management', async (data) => {
  try {
    const response = await axios.post('/customer-user/supplier-management', data)
    return response.status
  } catch (error) {
    return error
  }
})

export const updateSupplier = createAsyncThunk('/customer-user/supplier-management/update', async (rowData) => {
  try {
    const response = await axios.put('/customer-user/supplier-management/update', rowData)
    return response
  } catch (error) {
    return error
  }
})

export const tableDataSlice = createSlice({
  name: 'TableData',
  initialState: {
    tableData: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTableData.fulfilled, (state, action) => {
      state.tableData = action.payload
    })
  }
})

export default tableDataSlice.reducer
