

// Range 1300-1399
export default [
    {
      id: 1300,
      question: 'How to Register a Seller?',
      slug: 'register-seller',
      // answer: ansCreateStrongPassword
    },
    {
      id: 1301,
      question: 'How to edit a seller?',
      slug: 'edit-seller',
      // answer: ansChangePassword
    },
    {
      id: 1302,
      question: 'How to delete a seller?',
      slug: 'delete-seller',
      // answer: ansChangePassword
    },
  ]