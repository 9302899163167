export default `
<p> Please follow below steps to edit a supplier. </p>
<p> 
<ol>
  <li>Login to MProcure app </li>
  <li>On a left navbar, click on Services -> Suppliers </li>
  <li>Click on "Edit" button to edit an supplier, it will open up edit supplier popup </li>
  <li>Edit the required fields and click "Save" </li>
</ol>
<img src="${require('@src/views/publicServices/documentation/documentationRecords/images/edit-seller.png').default}" height="200"/>
</p>

`
