import mock from '../mock'

let supplierData = [
  {
    id: 1,
    supplierCode: Math.floor(Math.random() * 9999999).toString(),
    companyName: 'Microtech',
    officeAddress: 'Wonder IT park, Ghatkopar, Mumbai',
    officeType: 'Branch Office',
    category: 'PLATINUM',
    businessTillDate: Math.floor(Math.random() * 9999).toString(),
    status: 'PENDING_FOR_APPROVAL',
    gst: '18AABCU9603R1ZM',
    email: 'mail@microtect.com',
    contactName: 'MR. CEO',
    contactNumber: '8974563210',
    pinCode: '411052'
  }
]

mock.onGet('/customer-user/supplier-management').reply(() => [200, { data: supplierData }])
mock.onPost('/customer-user/supplier-management').reply((request) => {
  const { company, email, gst, mobile, name, pincode, address } = JSON.parse(request.data)
  const isGstExists = supplierData.find((each) => each.gst === gst)
  if (isGstExists === undefined) {
    const length = supplierData.length
    let lastIndex = 0
    if (length) {
      lastIndex = supplierData[length - 1].id
    }
    const tdata = {
      id: lastIndex + 1,
      supplierCode: Math.floor(Math.random() * 9999999).toString(),
      companyName: company,
      officeAddress: address,
      officeType: 'Branch Office',
      category: 'SILVER',
      businessTillDate: Math.floor(Math.random() * 9999).toString(),
      status: 'PENDING_FOR_APPROVAL',
      gst,
      email,
      pinCode: pincode,
      contactName: name,
      contactNumber: mobile.toString()
    }

    supplierData.push(tdata)
    const user = Object.assign({}, supplierData)
    return [200, user]
  }
  return [400]
})

mock.onPut('/customer-user/supplier-management/update').reply((request) => {
  try {
    const data = JSON.parse(request.data)
    const availableData = supplierData.find((obj) => obj.id === data.id)
    if (!availableData) {
      return alert('No Data available with this ID.')
    }
    const filteredData = supplierData.filter((x) => x.id !== data.id)
    data.data.id = data.id
    const formatedData = data.data
    const isGstExists = filteredData.filter((each) => each.gst === formatedData.gst)
    if (isGstExists.length === 0) {
      const DataToBeUpdated = {
        id: formatedData.id,
        supplierCode: Math.floor(Math.random() * 9999999).toString(),
        companyName: formatedData.company,
        officeAddress: formatedData.address,
        officeType: 'Branch Office',
        category: 'SILVER',
        businessTillDate: Math.floor(Math.random() * 9999).toString(),
        status: 'PENDING_FOR_APPROVAL',
        gst: formatedData.gst,
        email: formatedData.email,
        contactName: formatedData.name,
        pinCode: formatedData.pincode,
        contactNumber: formatedData.mobile.toString()
      }
      const newmppedData = [...filteredData, DataToBeUpdated]
      supplierData = [...newmppedData]
      const row = Object.assign({}, supplierData)
      return [200, row]
    }else{
      return [400]
    }
  } catch (error) {
    return error
  }
})

// ** LocationData...........

let locationData = [
  {
    id: 1,
    locationName: 'Mumbai',
    addressLine: 'Office no-32, Tech plaza',
    contactPersonName: 'Manoj Kumar',
    mobile: 9874563210,
    email: 'Manoj.k@gmail.com',
    gstNumber: '18AABCU9603R1ZM',
    state: 'Maharashtra',
    city: 'pune',
    postalCode: '411055',
    panNumber: 'BAEPJ0378M'
  }
]

mock.onGet('/customeradmin/roleassignment/location').reply(() => [200, { data: locationData }])

mock.onPut('/customeradmin/roleassignment/location/update').reply((request) => {
  try {
    const data = JSON.parse(request.data)
    const availableData = locationData.find((obj) => obj.id === data.id)
    if (!availableData) {
      return alert('No Data available with this ID.')
    }

    const filteredData = locationData.filter((x) => x.id !== data.id)
    data.data.id = data.id
    filteredData.push(data.data)
    locationData = [...filteredData]
    const row = Object.assign({}, locationData)
    return [200, row]
  } catch (error) {
    return console.log(error)
  }
})

mock.onPost('/customeradmin/roleassignment/location').reply((request) => {
  const { locationName, addressLine, contactPersonName, mobile, email, gstNumber, state, city, postalCode, panNumber } =
    JSON.parse(request.data)
  const length = locationData.length
  let lastIndex = 0
  if (length) {
    lastIndex = locationData[length - 1].id
  }
  const tdata = {
    id: lastIndex + 1,
    locationName,
    addressLine,
    contactPersonName,
    mobile,
    email,
    gstNumber,
    state,
    city,
    postalCode,
    panNumber
  }
  locationData.push(tdata)
  const data = Object.assign({}, locationData)
  return [200, data]
})

mock.onDelete('/customeradmin/roleassignment/location/delete').reply((request) => {
  const data = JSON.parse(request.data)
  const filteredData = locationData.filter((x) => x.id !== data.id)
  locationData = [...filteredData]
  return [200, filteredData]
})

// ** Role Assignment------------

export let userData = [
  {
    id: 1,
    name: 'Ratnakar apte',
    mobileNo: 9874563210,
    email: 'ratnakar@gmail.com',
    location: [{ value: 'PUNE', label: 'PUNE' }],
    roles: [{ value: 'USER', label: 'USER' }]
  },
  {
    id: 2,
    name: 'Vaibhav Landge',
    mobileNo: 9874563210,
    email: 'vaibhav@email.com',
    location: [{ value: 'NASHIK', label: 'NASHIK' }],
    roles: [
      { label: 'USER', value: 'USER' },
      { value: 'APPROVER', label: 'APPROVER' }
    ]
  }
]

mock.onGet('/customeradmin/roleassignment/users').reply(() => [200, { data: userData }])

mock.onPut('/customeradmin/roleassignment/users/update').reply((request) => {
  try {
    const data = JSON.parse(request.data)
    const availableData = userData.find((obj) => obj.id === data.id)
    if (!availableData) {
      return alert('No Data available with this ID.')
    }

    const filteredData = userData.filter((x) => x.id !== data.id)
    data.data.id = data.id
    filteredData.push(data.data)
    userData = [...filteredData]
    const row = Object.assign({}, userData)
    return [200, row]
  } catch (error) {
    console.log(error)
  }
})

mock.onPost('/customeradmin/roleassignment/users').reply((request) => {
  const { email, location, mobileNo, name, roles } = JSON.parse(request.data)
  const length = userData.length
  let lastIndex = 0
  if (length) {
    lastIndex = userData[length - 1].id
  }
  const tdata = {
    id: lastIndex + 1,
    name,
    mobileNo,
    email,
    location,
    roles
  }
  userData.push(tdata)
  const data = Object.assign({}, userData)
  return [200, data]
})

mock.onDelete('/customeradmin/roleassignment/users/delete').reply((request) => {
  const data = JSON.parse(request.data)
  const filteredData = userData.filter((x) => x.id !== data.id)
  userData = [...filteredData]
  return [200, filteredData]
})

// ** Price Structure...........

const mainData = [
  {
    suppliers0to30: 'INR 999.00',
    suppliers31to100: 'INR 14,999.00',
    suppliersgraterthan100: 'INR 1,999.00'
  }
]

const auctionData = [
  {
    AuctionPrice: '0.25% of Auction Amount'
  }
]

mock.onGet('/customeradmin/priceStructure').reply(() => [200, { data: { mainData, auctionData } }])

// ** T&C

let TCdata = [
  {
    id: 1,
    srNo: 1,
    heading: 'Heading',
    description: 'description'
  }
]

mock.onGet('/customeradmin/termsandconditions/add').reply(() => [200, { data: TCdata }])

mock.onPost('/customeradmin/termsandconditions/add').reply((request) => {
  const { heading, description } = JSON.parse(request.data)
  const length = TCdata.length
  let lastIndex = 0
  if (length) {
    lastIndex = TCdata[length - 1].id
  }
  const tdata = {
    id: lastIndex + 1,
    srNo: lastIndex + 1,
    heading,
    description
  }
  TCdata.push(tdata)
  const data = Object.assign({}, TCdata)
  return [200, data]
})

mock.onPut('/customeradmin/termsandconditions/update').reply((request) => {
  try {
    const data = JSON.parse(request.data)
    const availableData = TCdata.find((obj) => obj.id === data.id)
    if (!availableData) {
      return alert('No Data available with this ID.')
    }

    const filteredData = TCdata.filter((x) => x.id !== data.id)
    data.data.id = data.id
    filteredData.push(data.data)
    const newmppedData = filteredData?.map((i) => ({
      id: i.id,
      srNo: i.id,
      heading: i.heading,
      description: i.description
    }))
    TCdata = [...newmppedData]
    const row = Object.assign({}, TCdata)
    return [200, row]
  } catch (error) {
    console.log(error)
  }
})

mock.onDelete('/customeradmin/termsandconditions/delete').reply((request) => {
  const data = JSON.parse(request.data)
  const filteredData = TCdata.filter((x) => x.id !== data.id)
  TCdata = [...filteredData]
  return [200, filteredData]
})
