export default `
<p> Only admin can delete a user account. Please follow below steps to delete a user.  </p>
<p> 
<ol>
  <li>Login to MProcure app with admin role </li>
  <li>On a left navbar, click on Setting -> My Organization -> Users and Roles </li>
  <li>Click on "Delete" button to delete a user. </li>
  <li>Confirm the delete action. </li>
</ol>
<p> The user will be deleted from your company.</p>
<img src="${require('@src/views/publicServices/documentation/documentationRecords/images/edit-user.png').default}" height="170"/>
</p>
`
