export default `
<p> Please follow below steps to setup company profile as a buyer. </p>
<p> 
<ol>
  <li>Login to MProcure app </li>
  <li>On a left navbar, click on Setting -> My Organization -> General  </li>
  <li>Fill in "Basic Details" and click "Next". </li>
  <li>Fill in "Billing Address" and click "Next". </li>
  <li>Fill in "Contact Person Details" and click "Save". </li>
</ol>
<p> Congratulations! Your company profile is setup, and you can now proceed to add Branches and Users for your organization. </p>
<img src="${require('@src/views/publicServices/documentation/documentationRecords/images/general-setting-basic-details.png').default}" height="300"/>
</p>

`
