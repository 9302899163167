export default `
<p> Headquarter branch is added by default to your company. Please follow below steps to add branches other than headquarter in the organization. </p>
<p> 
<ol>
  <li>Login to MProcure app with admin role </li>
  <li>On a left navbar, click on Setting -> My Organization -> Branches  </li>
  <li>Click on "+ Add New Branch" button. </li>
  <li>Fill up branch details and click "Save". </li>
</ol>
<p> Congratulations! New branch is added to your company.</p>
<img src="${require('@src/views/publicServices/documentation/documentationRecords/images/add-branch.png').default}" height="400"/>
</p>

`
