

// Range 1300-1399
export default [
    {
      id: 1300,
      question: 'How to export supplier data in CSV?',
      slug: 'export-seller-csv',
      // answer: ansCreateStrongPassword
    },
    {
      id: 1301,
      question: 'How to import suppliers from CSV file?',
      slug: 'import-seller-csv',
      // answer: ansChangePassword
    },
  ]