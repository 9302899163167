// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchBranchs = createAsyncThunk('/branch-name', async (companyCode) => {
  try {
    const res = await fetch(process.env.REACT_APP_APOLLO_SERVER_URI, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
        query ListLocations($companyCode: String!) {
          listLocations(companyCode: $companyCode) {
            id
            locationName
            addressLine
            gstNumber
            state
            city
            postalCode
            panNumber
            companyCode
          }
        }`,
        variables: {
          companyCode
        }
      })
    })
    const response = await res.json()
    return await response.data.listLocations
  } catch (error) {
    throw new Error(error)
  }
})

export const branchesSlice = createSlice({
  name: 'branches',
  initialState: {
    existingBranches: [],
    branchName: ''
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBranchs.fulfilled, (state, action) => {
      state.existingBranches = action.payload
    })
  }
})

export default branchesSlice.reducer
