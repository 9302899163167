export default `
<p> Please follow below steps to delete a supplier. </p>
<p> 
<ol>
  <li>Login to MProcure app </li>
  <li>On a left navbar, click on Services -> Suppliers </li>
  <li>Click on "Delete" button to delete an supplier. </li>
  <li>Confirm the delete action. </li>
</ol>
</p>
<img src="${require('@src/views/publicServices/documentation/documentationRecords/images/edit-seller.png').default}" height="200"/>

`
