export default `
<p> Please follow below steps to self register on the MProcure platform. </p>
<p> 
<ol>
  <li>Open MProcure website (www.mprocure.in) and click on "Login". This will redirect to MProcure app and open the login page. </li>
  <li>On login page, click on "Create an account" link at the bottom </li>
  <li>Fill in the registration form and submit. </li>
</ol>
<p> Congratulations! Your registration has been completed, and you can now log in and configure your organization. </p>
<img src="${require('@src/views/publicServices/documentation/documentationRecords/images/register.png').default}" height="500"/>
</p>

`
