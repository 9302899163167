import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  selectedBuyer: {},
  selectedSeller: {},
  preferredVendor: {
    sellerId: '',
    supplierCode: '',
  },
}

export const mpRfqSlice = createSlice({
  name: 'mp-rfqs',
  initialState,
  reducers: {
    setSelectedBuyer: (state, actions) => {
      state.selectedBuyer = actions.payload
    },
    setSelectedSeller: (state, actions) => {
      state.selectedSeller = actions.payload
    },
    setSellerId: (state, actions) => {
      state.preferredVendor.sellerId = actions.payload.value
      state.preferredVendor.supplierCode = actions.payload.supplierCode
    },
    resetMpRFQState: (state, actions) => {
      return initialState
    }
  }
})

export const { setSelectedBuyer, setSelectedSeller, resetMpRFQState, setSellerId } = mpRfqSlice.actions
export default mpRfqSlice.reducer
