// cartSlice.js (or similar file)
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cartItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [],
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload
      const existingItemIndex = state.cartItems.findIndex((item) => item.itemId === newItem.itemId)
      if (existingItemIndex !== -1) {
        state.cartItems[existingItemIndex] = newItem
      } else {
        state.cartItems.push({ ...newItem })
      }
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    },

    updateCartItem: (state, action) => {
      const { itemId } = action.payload
      const index = state.cartItems.findIndex((item) => item.itemId === itemId)
      if (index !== -1) {
        state.cartItems[index] = action.payload
      }
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    },
    deleteCartItem: (state, action) => {
      const itemId = action.payload
      state.cartItems = state.cartItems.filter((item) => item.itemId !== itemId)
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    },
  },
})

export default cartSlice.reducer
export const { addToCart, updateCartItem, deleteCartItem } = cartSlice.actions
