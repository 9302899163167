import mock from '../mock'
import { v4 as uuid } from 'uuid'

const auctionCode = () => {
  const date = new Date()
  const month = date.getUTCMonth() + 1
  const year = date.getUTCFullYear()
  const newMonth = month < 10 ? '0' + month : month
  const theAuctionCode = `${year}-${newMonth}-${Math.random().toString(36).slice(8)}`
  return theAuctionCode
}

let auctionData = [
  {
    id: 1,
    auctionId: auctionCode(),
    customerId: uuid(),
    creatorId: uuid(),
    status: 'PENDINGFORAPPROVAL',
    poStatus: 'PENDING',
    remarks: [
      {
        id: 1,
        details: 'Need to add extra details',
        rules: 'Selected Duration is too long',
        bom: 'ok',
        information: 'Need new GST Number',
        bidders: 'okay',
        termsAndConditions: 'Perfect',
        createdAt: '2022-03-01T07:30:00.000Z'
      },
      {
        id: 2,
        details: 'details',
        rules: ' Duration is too long',
        bom: 'BOM',
        information: 'Need new GST Number',
        bidders: 'BIDDER',
        termsAndConditions: 'termsAndConditions',
        createdAt: '2022-03-03T07:30:00.000Z'
      }
    ],
    bidders: [
      {
        id: 1,
        supplierCode: '1923205',
        companyName: 'Microtech',
        Officeaddress: 'Wonder IT park, Ghatkopar, Mumbai',
        Officetype: 'Branch Office',
        category: 'PLATINUM',
        businessTillDate: '1107',
        status: 'PENDING_FOR_APPROVAL',
        gst: '18AABCU9603R1ZM',
        email: 'mail@microtect.com',
        contactName: 'MR. CEO',
        contactNumber: 8974563210
      }
    ],
    billOfMaterials: {
      bidMechanism: 'CUMULATIVE LEVEL',
      submittedWithTaxes: true,
      items: [
        {
          id: '4d0ce609-5920-4b17-a496-7aed183a91e2',
          description: 'Cement',
          unit: 'tons',
          quantity: 100
        },
        {
          id: '4d0ce609-5920-4b17-a496',
          description: 'LED Bulbs',
          unit: 'unit',
          quantity: 100
        }
      ]
    },
    details: {
      auctionTitle: 'auction for Materials',
      auctionDescription: 'description about this auction.',
      buyersName: 'John Doe',
      location: 'PUNE',
      budget: 50000.0,
      currency: 'INR',
      productType: 'GOODS',
      productSegment: 'AUTOMOBILE',
      approver: 'Vaibhav Landge',
      dislpayLeadingBidPrice: false
    },
    information: {
      deliveryAddress: 'pune',
      gstNumber: '18AABCU9603R1ZM',
      pinCode: '411001',
      uploadedFiles: []
    },
    rules: {
      auctionType: 'Reverse Auction',
      bidImprovement: 4,
      startDateTime: '2022-05-25T06:30:00.000Z',
      prebidDueDate: '2022-05-26T17:30:00.000Z',
      durationToEnd: 100,
      runOverTimeInMinutes: 2,
      activateRunOverBeforeEndInMinutes: 3,
      numberOfRunOverEvents: 4,
      activateRunOver: true,
      activatePrebidPrice: true
    },
    termsAndConditions: {
      acceptTermsAndConditions: false,
      tnc: [
        {
          id: '6604cb80-a513-4a36-9791-86f2b3416389',
          description: 'You should be in the business since 5 years',
          heading: 'In Business Since',
          srNo: 1
        },
        {
          id: '6604cb80-a513-4a36-9791',
          description: 'You should be in the business since 5 years',
          heading: 'In Business Since',
          srNo: 2
        }
      ]
    }
  }
]

mock.onGet('/services/auction').reply(() => [200, { data: auctionData }])

mock.onPost('/services/auction/getauction').reply((request) => {
  const data = JSON.parse(request.data)
  const auction = auctionData.find((x) => x.id === data)
  return [200, auction]
})

mock.onPost('/services/auction').reply((request) => {
  const data = JSON.parse(request.data)
  const length = auctionData.length
  let lastIndex = 0
  if (length) {
    lastIndex = auctionData[length - 1].id
  }
  let formWizard = {}
  let findData = auctionData.find((x) => x.id === data.id)
  const filterData = auctionData.filter((x) => x.id !== data.id)
  if (data.id && findData) {
    findData = data.auctionData
    const updateStatus = findData.details.approver === '' ? 'APPROVED' : 'PENDINGFORAPPROVAL'
    findData.status = updateStatus
    findData.createdAt = new Date().toISOString()
    filterData.push(findData)
  } else {
    const { auctionData } = data
    const updateStatus = auctionData.details.approver === '' ? 'APPROVED' : 'PENDINGFORAPPROVAL'
    formWizard = {
      id: lastIndex + 1,
      auctionCode: auctionCode(),
      auctionId: auctionCode(),
      customerId: uuid(),
      creatorId: uuid(),
      status: updateStatus,
      poStatus: 'PENDING',
      details: auctionData.details,
      bidders: auctionData.bidders,
      billOfMaterials: auctionData.billOfMaterials,
      information: auctionData.information,
      rules: auctionData.rules,
      termsAndConditions: auctionData.termsAndConditions
    }
  }
  if (data.id && findData) {
    auctionData = [...filterData]
  } else {
    auctionData.push(formWizard)
  }
  const auction = Object.assign({}, auctionData)
  return [200, auction]
})

mock.onPut('/services/auction/update').reply((request) => {
  try {
    const data = JSON.parse(request.data)
    const availableData = auctionData.find((obj) => obj.id === data.id)
    if (!availableData) {
      return alert('No Data available with this ID.')
    }
    availableData.status = data.status
    const remarks = data?.remarks
    const isApplicableForResent =
      remarks.bidders === '' &&
      remarks.bom === '' &&
      remarks.rules === '' &&
      remarks.details === '' &&
      remarks.termsAndConditions === ''

    if (remarks && !isApplicableForResent) {
      availableData.remarks.push(data?.remarks)
    }
    const filteredData = auctionData.filter((x) => x.id !== data.id)
    filteredData.push(availableData)
    auctionData = [...filteredData]
    const row = Object.assign({}, auctionData)
    return [200, row]
  } catch (error) {
    return console.log(error)
  }
})

const productSegment = {
  GOODS: [
    { value: 'AUTOMOBILE', label: 'AUTOMOBILE' },
    { value: 'BRASS HARDWARE & COMPONENTS', label: 'BRASS HARDWARE & COMPONENTS' },
    { value: 'CHEMICALS', label: 'CHEMICALS' },
    { value: 'COMPUTER HARDWARE & SOFTWARE', label: 'COMPUTER HARDWARE & SOFTWARE' },
    { value: 'CONSTRUCTION & REAL ESTATE', label: 'CONSTRUCTION & REAL ESTATE' },
    { value: 'CONSUMER ELECTRONICS', label: 'CONSUMER ELECTRONICS' },
    { value: 'ELECTRONICS & ELECTRICAL SUPPLIES', label: 'ELECTRONICS & ELECTRICAL SUPPLIES' },
    { value: 'ENERGY & POWER', label: 'ENERGY & POWER' },
    { value: 'ENVIRONMENT & POLLUTION', label: 'ENVIRONMENT & POLLUTION' },
    { value: 'FURNITURE', label: 'FURNITURE' },
    { value: 'HOSPITAL & MEDICAL SUPPLIES', label: 'HOSPITAL & MEDICAL SUPPLIES' },
    { value: 'HOTEL SUPPLIES & EQUIPMENT', label: 'HOTEL SUPPLIES & EQUIPMENT' },
    { value: 'INDUSTRIAL SUPPLIES', label: 'INDUSTRIAL SUPPLIES' },
    { value: 'MACHINERIES', label: 'MACHINERIES' },
    { value: 'OFFICE & SCHOOL SUPPLIES', label: 'OFFICE & SCHOOL SUPPLIES' },
    { value: 'PIPES, TUBES & FITTINGS', label: 'PIPES, TUBES & FITTINGS' },
    { value: 'PRINTING & PUBLISHING', label: 'PRINTING & PUBLISHING' },
    { value: 'SCIENTIFIC & LABORATORY INSTRUMENTS', label: 'SCIENTIFIC & LABORATORY INSTRUMENTS' },
    { value: 'SECURITY & PROTECTION', label: 'SECURITY & PROTECTION' },
    { value: 'TELECOMMUNICATIONS', label: 'TELECOMMUNICATIONS' },
    { value: 'TRANSPORTATION', label: 'TRANSPORTATION' }
  ],
  SERVICES: [
    {
      value: 'Building and Facility Construction and Maintenance Services',
      label: 'Building and Facility Construction and Maintenance Services'
    },
    {
      value: 'Industrial Production and Manufacturing Services',
      label: 'Industrial Production and Manufacturing Services'
    },
    { value: 'Industrial Cleaning Services', label: 'Industrial Cleaning Services' },
    { value: 'Environmental Services', label: 'Environmental Services' },
    { value: 'Transportation and Storage and Mail Services', label: 'Transportation and Storage and Mail Services' },
    {
      value: 'Management and Business Professionals and Administrative Services',
      label: 'Management and Business Professionals and Administrative Services'
    },
    {
      value: 'Engineering and Research and Technology Based Services',
      label: 'Engineering and Research and Technology Based Services'
    },
    {
      value: 'Editorial and Design and Graphic and Fine Art Services',
      label: 'Editorial and Design and Graphic and Fine Art Services'
    },
    {
      value: 'Public Utilities and Public Sector Related Services',
      label: 'Public Utilities and Public Sector Related Services'
    },
    { value: 'Financial and Insurance Services', label: 'Financial and Insurance Services' },
    { value: 'Healthcare Services', label: 'Healthcare Services' },
    { value: 'Education and Training Services', label: 'Education and Training Services' },
    {
      value: 'Travel and Food and Lodging and Entertainment Services',
      label: 'Travel and Food and Lodging and Entertainment Services'
    },
    { value: 'Personal and Domestic Services', label: 'Personal and Domestic Services' },
    {
      value: 'National Defense and Public Order and Security and Safety Services',
      label: 'National Defense and Public Order and Security and Safety Services'
    },
    { value: 'Politics and Civic Affairs Services', label: 'Politics and Civic Affairs Services' },
    { value: 'Organizations and Clubs', label: 'Organizations and Clubs' },
    {
      value: 'Land and Buildings and Structures and Thoroughfares',
      label: 'Land and Buildings and Structures and Thoroughfares'
    }
  ]
}

mock.onGet('/services/auction/productsegment').reply(() => [200, productSegment])

const bidderResponse = [
  {
    auctionId: 1,
    respondedBidderId: 6,
    preQualification: 'PENDING',
    bomResponse: [
      {
        id: 1,
        itemId: '4d0ce609-5920-4b17-a496-7aed183a91e2',
        unitRate: 500,
        totalAmount: 50000,
        productCode: 'Ambuja-ca01'
      },
      {
        id: 2,
        itemId: '4d0ce609-5920-4b17-a496',
        unitRate: 300.0,
        totalAmount: 30000.0,
        productCode: 'Syska-mn34'
      }
    ],
    tncResponse: [
      {
        id: 1,
        itemId: '6604cb80-a513-4a36-9791-86f2b3416389',
        agree: true
      },
      {
        id: 2,
        itemId: '6604cb80-a513-4a36-9791',
        agree: false
      }
    ]
  }
]

mock.onGet('/services/auction/bidder').reply(() => [200, bidderResponse])

mock.onPut('/services/auction/bidder/response').reply((request) => {
  try {
    const data = JSON.parse(request.data)
    const existingBidderResponse = bidderResponse.find(
      (item) => item.auctionId === data.auctionId && item.respondedBidderId === data.respondedBidderId
    )
    const existingBidderResponseIndex = bidderResponse.findIndex(
      (item) => item.auctionId === data.auctionId && item.respondedBidderId === data.respondedBidderId
    )
    if (existingBidderResponse) {
      // condition for upcoming bomResponse array
      if (existingBidderResponse.preQualification === 'COMPLETED') {
        existingBidderResponse.preQualification = 'COMPLETED'
      } else {
        existingBidderResponse.preQualification = data.status
      }

      data.bomResponse.forEach((item) => {
        const index = existingBidderResponse.bomResponse.findIndex((response) => {
          return response.itemId === item.itemId
        })
        index > -1 ? (existingBidderResponse.bomResponse[index] = item) : existingBidderResponse.bomResponse.push(item)
      })
      // condition for upcoming tncResponse array
      data.tncResponse.forEach((item) => {
        const index = existingBidderResponse.tncResponse.findIndex((response) => {
          return response.itemId === item.itemId
        })
        index > -1 ? (existingBidderResponse.tncResponse[index] = item) : existingBidderResponse.tncResponse.push(item)
      })
      //Save the updated responses
      bidderResponse[existingBidderResponseIndex] = existingBidderResponse
    } else {
      data.preQualification = auctionData.ruleDetails.radioValue.activateBidding ? 'PENDING' : 'NA'
      bidderResponse.push(data)
    }
    return [200, data]
  } catch (error) {
    return console.log(error)
  }
})
