export default `
<p> Please follow below steps to change your password. </p>
<p> 
<ol>
  <li>Login to MProcure app.</li>
  <li>On a left navbar, click on Setting -> My Profile -> Security </li>
  <li>Provide current password. </li>
  <li>Provide new password and click "Update Password". </li>
</ol>
<p> Your passoword is updated.</p>
<img src="${require('@src/views/publicServices/documentation/documentationRecords/images/change-password.png').default}" height="300"/>
</p>
`