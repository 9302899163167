import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'
import { RoleAbilityMapping } from '@src/configs/acl/roleAbilityMapping'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))

const roles = userData?.role
let existingAbility = []
roles &&
  roles.forEach((role) => {
    const roleInUpperCase = role.toUpperCase()
    const abilitiesWithRole = RoleAbilityMapping[roleInUpperCase]
    return (existingAbility = [...existingAbility, ...abilitiesWithRole])
  })
export default new Ability(existingAbility || initialAbility)
