import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  senderId: '',
  recipientId: ''
}

export const chatSlice = createSlice({
  name: 'rfq-chat',
  initialState,
  reducers: {
    setUserIds: (state, action) => {
      state.senderId = action.payload.senderId
      state.recipientId = action.payload.recipientId
    },
    resetChatUserIds: (state) => {
      return initialState
    }
  }
})

export const { setUserIds, resetChatUserIds } = chatSlice.actions
export default chatSlice.reducer
