import { createSlice } from '@reduxjs/toolkit'
import { getTotalAmount } from './bomTax.service'

const EMPTY_PURCHASE_ORDER = {
  id: '',
  purchaseOrderNumber: 0,
  poLink: '',
  status: '',
  project: {
    option: null,
    project: null
  },
  sellerAddress: {},
  billingAddress: {
    option: null,
    branch: null
  },
  shippingAddress: {
    option: null,
    branch: null
  },
  boms: [],
  termsAndConditions: [],
  buyerComment: '',
  otherCharges: {
    transport: {
      price: 0,
      gstPercentage: 0
    },
    handling: {
      price: 0,
      gstPercentage: 0
    },
    otherCharges: {
      price: 0,
      gstPercentage: 0
    }
  },
  isGstIncludedInPrice: false
}

export const PO_MODAL_MODE = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  CREATE: 'CREATE'
}

const purchaseOrderSlice = createSlice({
  name: 'purchaseOrders',
  initialState: {
    poModalMode: PO_MODAL_MODE.CREATE,
    activePurchaseOrder: EMPTY_PURCHASE_ORDER
  },
  reducers: {
    setPOModalMode(state, action) {
      state.poModalMode = action.payload
    },
    setActivePurchaseOrder(state, action) {
      state.activePurchaseOrder = { ...state.activePurchaseOrder, ...action.payload }
    },
    resetActivePurchaseOrder(state) {
      state.activePurchaseOrder = EMPTY_PURCHASE_ORDER
    },
    updateProject(state, action) {
      state.activePurchaseOrder.project = action.payload
    },
    updateSellerAddress(state, action) {
      state.activePurchaseOrder.sellerAddress = action.payload
    },
    updateBillingAddress(state, action) {
      state.activePurchaseOrder.billingAddress = action.payload
    },
    updateShippingAddress(state, action) {
      state.activePurchaseOrder.shippingAddress = action.payload
    },
    addBomItem(state, action) {
      const totalAmounts = getTotalAmount(action.payload)
      state.activePurchaseOrder.boms = state.activePurchaseOrder.boms.concat({
        ...action.payload,
        ...totalAmounts
      })
    },
    setRFQMaterials(state, action) {
      state.activePurchaseOrder.boms = action.payload
    },
    removeBomItem(state, action) {
      state.activePurchaseOrder.boms = state.activePurchaseOrder.boms.filter((item) => item.itemId !== action.payload)
    },
    editBomItem(state, action) {
      const { bomId, itemId } = action.payload
      const uniqueKey = `${bomId}-${itemId}`
      const totalAmounts = getTotalAmount(action.payload)
      const updatedItem = { ...action.payload, ...totalAmounts }
      if (state.activePurchaseOrder.boms?.length > 0) {
        state.activePurchaseOrder.boms = (state.activePurchaseOrder.boms || []).map((item) => {
          const itemKey = `${item.bomId}-${item.itemId}`
          return itemKey === uniqueKey ? updatedItem : item
        })
      } else {
        state.activePurchaseOrder.boms = [{ ...updatedItem }]
      }
    },
    addTermsAndConditions(state, action) {
      const { tncId, Description, heading, srNo } = action.payload
      const record = state.activePurchaseOrder?.termsAndConditions?.find((tc) => tc.srNo === +srNo)

      if (record) {
        const updatedRecord = { ...record, Description, heading }
        const filterArray = state.activePurchaseOrder.termsAndConditions.filter((f) => f.srNo !== +record.srNo)
        state.activePurchaseOrder.termsAndConditions = [...filterArray, updatedRecord]
      } else {
        const prevState = state.activePurchaseOrder.termsAndConditions || []
        state.activePurchaseOrder.termsAndConditions = [
          ...prevState,
          {
            tncId,
            Description,
            heading,
            srNo: Number(srNo)
          }
        ]
      }
    },
    setTermsAndConditions(state, action) {
      state.activePurchaseOrder.termsAndConditions = action.payload
    },
    deleteTermsAndConditions(state, action) {
      const updatedState = state.activePurchaseOrder.termsAndConditions
        .filter((item) => {
          return item.srNo !== +action.payload
        })
        .map((item) => ({ ...item }))
      state.activePurchaseOrder.termsAndConditions = updatedState
    },
    setBuyerComment(state, action) {
      state.activePurchaseOrder.buyerComment = action.payload
    },
    setTransportChargesInPO: (state, action) => {
      state.activePurchaseOrder.otherCharges.transport.price = action.payload.price
      state.activePurchaseOrder.otherCharges.transport.gstPercentage = action.payload.gstPercentage
    },
    setHandlingChargesInPO: (state, action) => {
      state.activePurchaseOrder.otherCharges.handling.price = action.payload.price
      state.activePurchaseOrder.otherCharges.handling.gstPercentage = action.payload.gstPercentage
    },
    setAdditionalChargesInPO: (state, action) => {
      state.activePurchaseOrder.otherCharges.otherCharges.price = action.payload.price
      state.activePurchaseOrder.otherCharges.otherCharges.gstPercentage = action.payload.gstPercentage
    },
    setIsGstIncludedInPrice: (state, action) => {
      state.activePurchaseOrder.isGstIncludedInPrice = action.payload
    }
  }
})

export const {
  setActivePurchaseOrder,
  updateProject,
  updateSellerAddress,
  updateBillingAddress,
  updateShippingAddress,
  addBomItem,
  updateBomItem,
  setRFQMaterials,
  removeBomItem,
  resetActivePurchaseOrder,
  editBomItem,
  addTermsAndConditions,
  setTermsAndConditions,
  deleteTermsAndConditions,
  setPOModalMode,
  setBuyerComment,
  setTransportCharges,
  setTransportChargesInPO,
  setHandlingChargesInPO,
  setAdditionalChargesInPO,
  setIsGstIncludedInPrice
} = purchaseOrderSlice.actions

export default purchaseOrderSlice.reducer
