import jwtDefaultConfig from '@src/@core/auth/jwt/jwtDefaultConfig'
// When using loose Javascript files:

// Modules, e.g. Webpack:
var AmazonCognitoIdentity = require('amazon-cognito-identity-js')
const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
}

export default class AuthenticationService {
  jwtConfig = { ...jwtDefaultConfig }
  userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
  }

  getCurrentUser() {
    return this.userPool.getCurrentUser()
  }

  getCurrentUserAttributes() {
    return new Promise((resolve, reject) => {
      const currentUser = this.getCurrentUser()
      currentUser.getSession(function (err, session) {
        if (err) {
          reject(err)
          return
        }
        currentUser.getUserAttributes(function (err, attribute) {
          if (err) {
            reject(err)
          } else {
            resolve(attribute)
          }
        })
      })
    })
  }

  async login(...args) {
    try {
      const { email, password } = args[0]
      var authenticationData = {
        Username: email,
        Password: password
      }
      var authenticationDetails = await new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)
      var userData = {
        Username: email,
        Pool: this.userPool
      }
      var cognitoUser = await new AmazonCognitoIdentity.CognitoUser(userData)
      return this.authenticateUser(cognitoUser, authenticationDetails)
    } catch (err) {
      return Promise.reject(err.message)
    }
  }

  authenticateUser(cognitoUser, authenticationDetails) {
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async function (result) {
          await cognitoUser.getUserAttributes(function (err, attribute) {
            if (err) {
              resolve({ result })
            } else {
              resolve({ result, attribute })
            }
          })
        },
        onFailure: function (err) {
          reject(err.message || JSON.stringify(err))
        }
      })
    })
  }

  changeCurrentPassword(password, newPassword) {
    return new Promise((resolve, reject) => {
      const currentUser = this.getCurrentUser()
      currentUser.getSession(function (err, session) {
        if (err) {
          reject(err)
          return
        }

        currentUser.changePassword(password, newPassword, (err, result) => {
          if (err) reject(err)
          resolve(result)
        })
      })
    })
  }

  createAttribute(name, value) {
    return new AmazonCognitoIdentity.CognitoUserAttribute({
      Name: name,
      Value: value
    })
  }

  register(...args) {
    try {
      const { firstname, lastname, email, password } = args[0]
      var attributes = [
        this.createAttribute('custom:role', 'Customer Admin'),
        this.createAttribute('custom:username', email),
        this.createAttribute('custom:firstName', firstname),
        this.createAttribute('custom:lastName', lastname)
      ]
      return new Promise((resolve, reject) =>
        this.userPool.signUp(email, password, attributes, null, (err, result) => {
          if (err) {
            reject(err)
          } else {
            resolve(result)
          }
        })
      )
    } catch (err) {
      return Promise.reject()
    }
  }

  completeNewPassword(user, password) {
    try {
      return Promise.resolve(Auth.completeNewPassword(user, password))
    } catch (error) {
      return Promise.reject()
    }
  }

  forgotPassowrd(email) {
    try {
      return new Promise((resolve, reject) => {
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
          Username: email,
          Pool: this.userPool
        })
        cognitoUser.forgotPassword({
          onSuccess: function (result) {
            resolve(result)
          },
          onFailure: function (err) {
            reject(err)
          }
        })
      })
    } catch (error) {
      return Promise.reject()
    }
  }

  confirmPassword(email, varificationCode, password) {
    try {
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: this.userPool
      })
      return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(varificationCode, password, {
          onFailure: (error) => {
            reject(error)
          },
          onSuccess: (response) => {
            resolve(response)
          }
        })
      })
    } catch (error) {
      return Promise.reject()
    }
  }

  getToken() {
    return new Promise((resolve, reject) => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData?.accessToken) {
        resolve(userData?.accessToken)
      } else {
        reject(new Error('No token found'))
      }
    })
  }

  getAccessToken = () => {
    return new Promise((resolve, reject) => {
      const currentUser = this.getCurrentUser()

      if (!currentUser) {
        return reject('No current user.')
      }

      // Add a retry mechanism for session retrieval
      const retrySession = (attempts) => {
        if (attempts === 0) {
          return reject('Failed to get session after multiple attempts.')
        }

        currentUser.getSession((err, session) => {
          if (err) {
            console.warn(`Error getting session: ${err.message || JSON.stringify(err)}. Retrying...`)
            // Retry session retrieval after a delay if error occurs
            setTimeout(() => retrySession(attempts - 1), 500)
          } else if (session.isValid()) {
            const accessToken = session.getAccessToken().getJwtToken()
            const refreshToken = session.getRefreshToken().getToken()
            const userData = JSON.parse(localStorage.getItem('userData')) || {}
            userData.accessToken = accessToken
            userData.refreshToken = refreshToken
            localStorage.setItem('userData', JSON.stringify(userData))
            return resolve(session)
          } else {
            console.warn('Session is invalid. Retrying...')
            setTimeout(() => retrySession(attempts - 1), 500)
          }
        })
      }

      retrySession(3) // Try 3 times to get the session
    })
  }
}
