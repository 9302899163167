
// Range 1300-1399
export default [
    {
      id: 1300,
      question: 'What is the auction workflow?',
      slug: 'what-is-auctionflow',
      // answer: ansCreateStrongPassword
    },
    {
      id: 1301,
      question: 'How to create an auction?',
      slug: 'create-auction',
      // answer: ansChangePassword
    },
    {
      id: 1302,
      question: 'How to approve an auction?',
      slug: 'approve-auction',
      // answer: ansChangePassword
    },
    {
      id: 1303,
      question: 'How to view live auction?',
      slug: 'buyer-live-auction',
      // answer: ansChangePassword
    },
    {
      id: 1304,
      question: 'How to shortlist a supplier post auction?',
      slug: 'shortlist-supplier',
      // answer: ansChangePassword
    },
    {
      id: 1305,
      question: 'How to award a supplier post auction?',
      slug: 'award-to-supplier',
      // answer: ansChangePassword
    },
    {
      id: 1306,
      question: 'How to issue PO to awarded supplier?',
      slug: 'issue-po-to-supplier',
      // answer: ansChangePassword
    },
  ]