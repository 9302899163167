export default `
<p> The "Admin" user is added by default to your company. Please follow below steps to add new user and assign role. </p>
<p> 
<ol>
  <li>Login to MProcure app with admin role </li>
  <li>On a left navbar, click on Setting -> My Organization -> Users and Roles </li>
  <li>Click on "+ Add New User" button. </li>
  <li>Fill up the user's details. </li>
  <li>Select roles from dropdown and click "Save". </li>
</ol>
<p> Congratulations! New User is added to your company.</p>
<img src="${require('@src/views/publicServices/documentation/documentationRecords/images/add-user.png').default}" height="400"/>
</p>

`
