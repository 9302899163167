import registerBuyer from "./ans-register-new-buyer"
import setupOrg from "./ans-setup-org"
import addCompanyLogo from "./ans-add-company-logo"
import whatIsAdmin from "./ans-what-is-admin"
import addUsers from "./ans-add-users"
import whatIsHeadquarter from "./ans-what-is-headquarter"
import addBranches from "./ans-add-branches"

// Range 1200-1299
export default [
    {
      id: 1200,
      question: 'How to register as a buyer?',
      slug: 'register-new-buyer',
      answer: registerBuyer
    },
    {
      id: 1201,
      question: "How to setup my company's profile?",
      slug: 'setup-myorg',
      answer: setupOrg
    },
    {
      id: 1202,
      question: "How to add company logo?",
      slug: 'add-myorg-logo',
      answer: addCompanyLogo
    },
    {
      id: 1203,
      question: "What is admin user?",
      slug: 'what-is-admin',
      answer: whatIsAdmin
    },
    {
      id: 1204,
      question: "How to add users and assign roles?",
      slug: 'add-users',
      answer: addUsers
    },
    {
      id: 1205,
      question: "What is 'Headquarter' branch?",
      slug: 'what-is-headquarter-branch',
      answer: whatIsHeadquarter
    },
    {
      id: 1206,
      question: "How to add branches?",
      slug: 'add-branches',
      answer: addBranches
    },
  ]