import mock from '../mock'
import jwt from 'jsonwebtoken'

const data = {
  users: [
    {
      id: 1,
      fullName: 'John Doe',
      username: 'johndoe',
      password: 'admin',
      avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
      email: 'admin@demo.com',
      role: 'admin',
      ability: [
        {
          action: 'manage',
          subject: 'all'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    },
    {
      id: 2,
      fullName: 'Jane Doe',
      username: 'janedoe',
      password: 'client',
      avatar: require('@src/assets/images/avatars/1-small.png').default,
      email: 'client@demo.com',
      role: 'client',
      ability: [
        {
          action: 'read',
          subject: 'ACL'
        },
        {
          action: 'read',
          subject: 'Auth'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    },
    {
      id: 3,
      fullName: 'Alia Bhat',
      username: 'alia.bhat',
      password: 'customer',
      avatar: require('@src/assets/images/avatars/1-small.png').default,
      email: 'customer.admin@demo.com',
      role: 'Customer Admin',
      companyCode: 'pre9byawr',
      ability: [
        {
          action: 'allow',
          subject: 'acl-common'
        },
        {
          action: 'allow',
          subject: 'acl-services'
        },
        {
          action: 'allow',
          subject: 'acl-billing'
        },
        {
          action: 'allow',
          subject: 'customer-admin'
        },
        {
          action: 'allow',
          subject: 'auction-service'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    },
    {
      id: 4,
      fullName: 'Nick Jonas',
      username: 'nick.jonas',
      password: 'customer',
      avatar: require('@src/assets/images/avatars/2-small.png').default,
      email: 'approver@demo.com',
      role: 'Approver',
      ability: [
        {
          action: 'allow',
          subject: 'acl-common'
        },
        {
          action: 'allow',
          subject: 'acl-services'
        },
        {
          action: 'allow',
          subject: 'auction-service'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    },
    {
      id: 5,
      fullName: 'Priyanka Chopra',
      username: 'priyanka.chopra',
      password: 'customer',
      avatar: require('@src/assets/images/avatars/11-small.png').default,
      email: 'customer.user@demo.com',
      role: 'Customer User',
      companyCode: 'pre9byawr',
      ability: [
        {
          action: 'allow',
          subject: 'acl-common'
        },
        {
          action: 'allow',
          subject: 'acl-services'
        },
        {
          action: 'allow',
          subject: 'auction-service'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    },
    {
      id: 6,
      fullName: 'Salman Khan',
      username: 'samlan.khan',
      password: 'customer',
      avatar: require('@src/assets/images/avatars/2-small.png').default,
      email: 'customer.supplier@demo.com',
      role: 'Customer Supplier',
      companyCode: 'pre9byawr',
      ability: [
        {
          action: 'allow',
          subject: 'acl-common'
        },
        {
          action: 'allow',
          subject: 'customer-supplier'
        },
        {
          action: 'allow',
          subject: 'auction-service'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    },
    {
      id: 14,
      fullName: 'Amir Khan',
      username: 'Amir.khan',
      password: 'customer',
      avatar: require('@src/assets/images/avatars/2-small.png').default,
      email: 'supplier@demo.com',
      role: 'Customer Supplier',
      ability: [
        {
          action: 'allow',
          subject: 'acl-common'
        },
        {
          action: 'allow',
          subject: 'customer-supplier'
        },
        {
          action: 'allow',
          subject: 'auction-service'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    },
    {
      id: 7,
      fullName: 'Akshay Kumar',
      username: 'akshay.kumar',
      password: 'supplier',
      avatar: require('@src/assets/images/avatars/2-small.png').default,
      email: 'supplier@demo.com',
      role: 'Supplier Sales Executive',
      ability: [
        {
          action: 'allow',
          subject: 'acl-common'
        },
        {
          action: 'allow',
          subject: 'supplier-sales-exec'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    }
  ]
}

const registerdata = {
  users: [
    {
      id: 1,
      organization: 'Org One',
      address: 'karvenagar, pune',
      postalcode: '411052',
      avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
      city: 'pune',
      state: 'Maharashtra',
      telephone: '1234567890',
      contactperson: 'first persone',
      contactnumber: '9876543210',
      designation: 'Manager',
      email: 'register@demo.com',
      website: 'abc.com',
      industrytype: 'Information and Techonology',
      annualrevenue: '500000',
      employees: '20',
      gst: '18AABCU9603R1ZM',
      pan: 'abcde1234f',
      password: 'Manager.123',
      confirmpassword: 'Manager.123',
      termsandconditions: false,
      ability: [
        {
          action: 'manage',
          subject: 'all'
        }
      ],
      extras: {
        eCommerceCartItemsCount: 5
      }
    }
  ]
}

// ! These two secrets shall be in .env file and not in any other file
const jwtConfig = {
  secret: 'dd5f3089-40c3-403d-af14-d0c228b05cb4',
  refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
  expireTime: '10m',
  refreshTokenExpireTime: '10m'
}

mock.onPost('/jwt/login').reply((request) => {
  const { email, password } = JSON.parse(request.data)

  let error = {
    email: ['Something went wrong']
  }

  const user = data.users.find((u) => u.email === email && u.password === password)

  if (user) {
    try {
      const accessToken = jwt.sign({ id: user.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
      const refreshToken = jwt.sign({ id: user.id }, jwtConfig.refreshTokenSecret, {
        expiresIn: jwtConfig.refreshTokenExpireTime
      })

      const userData = { ...user }

      delete userData.password

      const response = {
        userData,
        accessToken,
        refreshToken
      }

      return [200, response]
    } catch (e) {
      error = e
    }
  } else {
    error = {
      email: ['Email or Password is Invalid']
    }
  }

  return [400, { error }]
})

mock.onPost('/jwt/register').reply((request) => {
  if (request.data.length > 0) {
    const { email, password } = JSON.parse(request.data)
    const isEmailAlreadyInUse = registerdata.users.find((user) => user.email === email)
    const error = {
      email: isEmailAlreadyInUse ? 'This email is already in use.' : null
    }

    if (!error.email) {
      const userData = {
        email,
        password,
        username: 'superadmin',
        fullName: 'Super Admin',
        avatar: require('@src/assets/images/avatars/1-small.png').default,
        role: 'admin',
        ability: [
          {
            action: 'manage',
            subject: 'all'
          }
        ]
      }

      // Add user id
      const length = registerdata.users.length
      let lastIndex = 0
      if (length) {
        lastIndex = registerdata.users[length - 1].id
      }
      userData.id = lastIndex + 1

      registerdata.users.push(userData)

      const accessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })

      const user = Object.assign({}, userData)
      delete user['password']
      const response = { user, accessToken }

      return [200, response]
    } else {
      return [200, { error }]
    }
  }
})

mock.onPost('/jwt/refresh-token').reply((request) => {
  const { refreshToken } = JSON.parse(request.data)

  try {
    const { id } = jwt.verify(refreshToken, jwtConfig.refreshTokenSecret)

    const userData = { ...data.users.find((user) => user.id === id) }

    const newAccessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expiresIn })
    const newRefreshToken = jwt.sign({ id: userData.id }, jwtConfig.refreshTokenSecret, {
      expiresIn: jwtConfig.refreshTokenExpireTime
    })

    delete userData.password
    const response = {
      userData,
      accessToken: newAccessToken,
      refreshToken: newRefreshToken
    }

    return [200, response]
  } catch (e) {
    const error = 'Invalid refresh token'
    return [401, { error }]
  }
})
