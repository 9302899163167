export default `
<p> Please follow below steps to add/change company logo. </p>
<p> 
<ol>
  <li>Goto Setting -> My Organization -> General -> Basic Details </li>
  <li>click on "Upload" to uplaod the company logo </li>
  <li>Select a logo from local computer and uplaod</li>
</ol>
<img src="${require('@src/views/publicServices/documentation/documentationRecords/images/general-setting-basic-details.png').default}" height="300"/>
</p>

`