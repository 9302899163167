import accountSetting from './gettingStarted/accountSetting/accountSetting'
import buyerOnboarding from './gettingStarted/buyerOnboarding/buyerOnboarding'
import sellerOnboarding from './gettingStarted/sellerOnboarding/sellerOnboarding'
import bgSupplierMgmt from './buyerGuide/supplierManagement/bgSupplierMgmt'
import bgAuction from './buyerGuide/auction/bgAuction'
import bgTemplates from './buyerGuide/templates/bgTemplates'
import bgImportExport from './buyerGuide/importExport/bgImportExport'
import sgAuction from './sellerGuide/auction/sgAuction'


export default {
  documentationCategories: [  // id range 1-19
    {
      id: 1,
      category: 'getting-started',
      img: require('@src/assets/images/illustration/sales.svg').default,
      title: 'Getting Started',
      desc: "Whether you're new or you're a power user, this guide will help you."
    },
    {
      id: 2,
      category: 'buyer-guide',
      img: require('@src/assets/images/illustration/marketing.svg').default,
      title: "Buyer's Guide",
      desc: "Are you a buyer? This guide is for you!"
    },
    {
      id: 3,
      category: 'seller-guide',
      img: require('@src/assets/images/illustration/api.svg').default,
      title: "Seller's Guide",
      desc: "Hey Seller, here's a guide for you."
    },
    {
      id: 4,
      category: 'rfx',
      img: require('@src/assets/images/illustration/email.svg').default,
      title: 'RFx',
      desc: 'Complete guide for RFI, RFP and RFQ.'
    },
    {
      id: 5,
      category: 'auction',
      img: require('@src/assets/images/illustration/demand.svg').default,
      title: 'E-Auction',
      desc: 'Guide to the next generation negotiation'
    },
    {
      id: 6,
      category: 'templates',
      img: require('@src/assets/images/illustration/personalization.svg').default,
      title: 'Templates',
      desc: 'Reusable Templates are fun to work with'
    },
  ],
  'getting-started': [    // id range 20-29
    {
      id: 23,
      title: 'Buyer Onboarding',
      icon: 'Link',
      iconColor: 'text-primary',
      // id range 1200-1299
      questions: buyerOnboarding
    },
    {
      id: 24,
      title: 'Seller Onboarding',
      icon: 'FileText',
      iconColor: 'text-primary',
      // id range 1300-1399
      questions: sellerOnboarding
    },
    {
      id: 21,
      title: 'Account Settings',
      icon: 'Settings',
      iconColor: 'text-primary',
      // id range 1000-1099
      questions: accountSetting
    }
  ],
  'buyer-guide': [    // id range 30-39
    {
      id: 31,
      title: 'Supplier Management',
      icon: 'Lock',
      iconColor: 'text-warning',
      // id range 1400-1499
      questions: bgSupplierMgmt
    },
    {
      id: 32,
      title: 'Auction',
      icon: 'HelpCircle',
      iconColor: '',
      // id range 1500-1599
      questions: bgAuction
    },
    {
      id: 33,
      title: 'Template',
      icon: 'HelpCircle',
      iconColor: '',
      // id range 1600-1699
      questions: bgTemplates
    },
    {
      id: 34,
      title: 'Import & Export',
      icon: 'Smartphone',
      iconColor: 'text-info',
      questions: bgImportExport
    },
  ],
  'seller-guide': [     // id range 40-49
    {
      id: 40,
      title: 'Auction',
      icon: 'Settings',
      iconColor: 'text-primary',
      // id range 1700-1799
      questions: sgAuction
    },
  ],
  'templates': [        // id range 50-59
    {
      id: 50,
      title: 'Auction Templates',
      icon: 'Settings',
      iconColor: 'text-primary',
      // id range 1800-1899
      questions: bgTemplates
    },
    {
      id: 51,
      title: 'TnC Templates',
      icon: 'Settings',
      iconColor: 'text-primary',
      // id range 1900-1999
      questions: bgTemplates
    },
  ],
  // 'rfx': [              // id range 60-69
  //   {
  //     id: 60,
  //     title: 'Account Settings',
  //     icon: 'Settings',
  //     iconColor: 'text-primary',
  //     // id range 2000-2099
  //     // questions: accountSetting
  //   },
  // ],
  'auction': [          // id range 70-79
    {
      id: 70,
      title: 'Auction for Buyer',
      icon: 'Settings',
      iconColor: 'text-primary',
      // id range 2100-2199
      questions: bgAuction
    },
    {
      id: 71,
      title: 'Auction for Seller',
      icon: 'Settings',
      iconColor: 'text-primary',
      // id range 2200-2299
      questions: sgAuction
    },
  ]
}