import ansHowToLogin from "./ans-how-to-login"
import changeYourName from "./ans-change-your-name"
import ansChangeProfilePic from "./ans-change-profile-pic"
import ansChangeMobileNumber from "./ans-change-mobile-number"
import ansDeleteAccount from "./ans-delete-account"
import ansChangePassword from "./ans-change-password"

// Range 1000-1099
export default [
    {
      id: 1006,
      question: 'How to login to MProcure app?',
      slug: 'login',
      answer: ansHowToLogin
    },
    {
      id: 1000,
      question: 'Changing your name?',
      slug: 'change-your-name',
      answer: changeYourName
    },
    {
      id: 1001,
      question: 'Changing your profile picture?',
      slug: 'change-profile-pic',
      answer: ansChangeProfilePic
    },
    {
      id: 1002,
      question: 'Changing your mobile number?',
      slug: 'change-mobile-number',
      answer: ansChangeMobileNumber
    },
    {
      id: 1003,
      question: 'Deleting your user account?',
      slug: 'delete-user-account',
      answer: ansDeleteAccount
    },
    {
      id: 1004,
      question: 'How to change password?',
      slug: 'change-password',
      answer: ansChangePassword
    },
  ]