import ansAddSeller from "./ans-add-seller"
import ansEditSeller from "./ans-edit-seller"
import ansDeleteSeller from "./ans-delete-seller"

// Range 1300-1399
export default [
    {
      id: 1300,
      question: 'How to Register a Seller?',
      slug: 'register-seller',
      answer: ansAddSeller
    },
    {
      id: 1301,
      question: 'How to edit a seller?',
      slug: 'edit-seller',
      answer: ansEditSeller
    },
    {
      id: 1302,
      question: 'How to delete a seller?',
      slug: 'delete-seller',
      answer: ansDeleteSeller
    },
  ]