// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getpriceStructure = createAsyncThunk('/customeradmin/priceStructure', async () => {
    try {
        const response = await axios.get('/customeradmin/priceStructure')
        return response.data
    } catch (error) {
     console.log(error)   
    }
})

export const priceStructureSlice = createSlice({
  name: 'TableData',
  initialState: {
    priceStructure: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getpriceStructure.fulfilled, (state, action) => {
        state.priceStructure = action.payload
      })
  }
})

export default priceStructureSlice.reducer
