import ansAddSeller from "../../gettingStarted/sellerOnboarding/ans-add-seller"
import ansEditSeller from "../../gettingStarted/sellerOnboarding/ans-edit-seller"
import ansDeleteSeller from "../../gettingStarted/sellerOnboarding/ans-delete-seller"

// Range 1400-1499
export default [
    {
      id: 1400,
      question: 'How to Register a Seller?',
      slug: 'register-seller',
      answer: ansAddSeller
    },
    {
      id: 1401,
      question: 'How to edit a seller?',
      slug: 'edit-seller',
      answer: ansEditSeller
    },
    {
      id: 1402,
      question: 'How to delete a seller?',
      slug: 'delete-seller',
      answer: ansDeleteSeller
    },
  ]